$blue: #125185;
$grey: #333333;
$yellow: #fccd2c;

.wp-block-buttons {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .wp-block-button {
    .wp-element-button {
      padding: 0.75rem 2rem;
      background: $blue;
      color: white;
      text-decoration: none;
      border-radius: 0.2rem;
      font-weight: bold;
      margin: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        background: $grey;
        transition: all 0.2s ease;
      }
    }
  }
}

.wp-block-image {
  display: inline;
  margin: 0;
  overflow: hidden;
  img {
    border-radius: 4px;

    width: 100%;
  }
  figcaption {
    display: none;
  }
  &.alignleft {
    margin-right: 1rem;
    float: left;
  }
}

.wp-block-columns {
  // auto grid
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}

p.highlight {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: $blue;
}

.wp-block-separator {
  border: 0px;
  border-bottom: 1px solid #ccc;
  margin: 3rem 0;
}

.content {
  h3.wp-block-heading {
    display: block;
    position: relative;
    &:after {
      width: 3rem;
      content: "";
      position: absolute;
      height: 0.25rem;
      background: $blue;
      bottom: -0.2rem;
      left: 0;
      border-radius: 3px;
    }
  }
}

.check {
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 0.5rem;
    display: block;
    list-style: none;
    padding: 0;
    padding-left: 2rem;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      content: "✓";
      color: $blue;
    }
  }
}

ol {
  counter-reset: item;
  margin: 0;
  padding: 0;
}
ol li {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}
ol li:before {
  position: absolute;
  left: 0;
  content: counter(item) ". ";
  counter-increment: item;
  color: $blue;
  font-weight: bold;
}

.content {
  .inner {
    h2.wp-block-heading {
      font-size: 2rem;
      color: $blue;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    h3.wp-block-heading {
      margin-top: 2rem;
    }
  }
}

.blocklist {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ebebeb;
    padding: 1.5rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    strong {
      display: block;
      margin-bottom: 0.5rem;
      color: $blue;
    }
  }
}

.box-teaser {
  position: absolute;
  top: 0;
  left: 1rem;
  background: $yellow;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 0 0 6px 6px;
  text-transform: uppercase;
  color: $blue;
}
