@font-face {
    font-family: "Calibri";
    font-weight: normal;
    src: url("./assets/calibri-regular.ttf");
}

@font-face {
    font-family: "Calibri";
    font-weight: bold;
    src: url("./assets/calibri-bold.ttf");
}

* {
    box-sizing: border-box;
}
a {
    &:hover {
        text-decoration: underline;
    }
    color: #333;
}
body,
html {
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: "Calibri";
    background-color: #eeeeee;
    color: #333;
    padding-bottom: 64px;
}

h2 {
    margin: 0;
    font-size: 42px;
    margin-bottom: 12px;
    @media only screen and (max-width: 1024px) {
        font-size: 24px;
    }
}
