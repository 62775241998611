.header {
    background-repeat: no-repeat;
    background-color: #fccd2c;
    width: 100%;
    height: 500px;
    position: relative;
    .dtbiglogo {
        max-height: 70%;
    }
    .buslogo {
        max-height: 75%;
    }
    &:before {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.8;
    }
    .info {
        position: absolute;
        bottom: 54px;
        font-size: 24px;
        max-width: 600px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #125185;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 14px;
            left: 16px;
            transform: translateX(0);
        }
    }
    &.spiel {
        background-color: transparent;
        background-image: url("../assets/spiel_header.png");
        background-size: cover;
        background-position: center;
        @media only screen and (max-width: 1024px) {
            background-size: 200%;
            background-color: #f6cc03;
        }
    }
    .header-container {
        max-width: 1100px;
        margin: 0 auto;
        z-index: 999;
        position: relative;

        .header-title {
            margin-top: 48px;
        }
    }
    @media only screen and (max-width: 1024px) {
        height: 450px;
        background-image: none;
        .header-container {
            margin: 0 12px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .dtbiglogo {
            height: auto;
            width: 100%;
        }
        .buslogo {
            display: none;
        }
    }
    &.small {
        height: 380px;
        background-position: center;
        background-size: cover;
    }
}

.mobile-header {
    display: none;
    @media only screen and (max-width: 1024px) {
        display: block;
        text-align: center;
        width: 100%;
        color: white;
        padding: 12px;
        border-bottom: 1px rgba(255, 255, 255, 0.25) solid;
    }
}

.formular-field2 {
    margin: 0 auto;
    margin-top: 16px;
    max-width: 1100px;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 16px;
}

.infobox {
    border: 1px solid #707070;
    background-color: #125185;
    color: white;
    text-align: center;
    max-width: 1100px;
    margin: 64px auto;
    padding: 32px 64px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    @media only screen and (max-width: 1024px) {
        margin: 32px 12px;
        padding: 32px;
    }
}

.header-title-single {
    color: white;
    margin: 0 auto;
    max-width: 1100px;
    padding-top: 200px;
    &.black {
        color: black;
        padding-top: 52px;
    }
    @media only screen and (max-width: 1024px) {
        margin-left: 12px;
    }
}

.content {
    margin: 0 auto;
    max-width: 1100px;
    font-size: 20px;
    .inner {
        background: white;
        padding: 3rem;
        @media (max-width: 1024px) {
            padding: 1rem;
        }
    }
    @media only screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;

        flex-direction: column;
        margin: 12px;
        p,
        ul {
            max-width: 90%;
        }
    }
}

.download-set {
    img {
        height: 36px;
        margin-right: 16px;
    }
}
.formular-title {
    color: #125185;
    font-size: 36px;
    margin-bottom: 12px;
    font-weight: bold;
    @media only screen and (max-width: 1024px) {
        font-size: 24px;
    }
}
.job-text {
    font-size: 16px;
    margin-top: 12px;
}
.job-formular-content {
    padding: 24px;
    background-color: white;
}
.job-label {
    margin-bottom: 8px;

    color: #333333;
    font-weight: bold;
    display: block;
    margin-top: 12px;
}
.job-input {
    height: 42px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 3px;
    width: 100%;
    font-size: 16px;
    padding: 8px;
}
.job-button {
    width: 100%;
    background-color: #125185;
    color: white;
    text-align: center;
    padding: 16px;
    cursor: pointer;
    border-radius: 3px;
    letter-spacing: 1.6px;
    margin-top: 24px;
    appearance: none;
    border: 0px;
    text-transform: uppercase;
    &:hover {
        background: #f6cc03;
    }
}
.spiel-content {
    margin: 0 auto;
    max-width: 800px;
}
.spiel-formular {
    margin-top: -30px;
    @media only screen and (max-width: 1024px) {
        margin-top: -80px;
    }
    label {
        color: #333333;
        font-weight: bold;
        display: block;
        margin-top: 12px;
    }
    input[type="checkbox"] {
        height: 12px;
        width: 12px;
        display: inline;
    }
    .cr_form-inputgroup--typecheckbox {
        margin-top: 16px;

        display: block;
        label {
            display: inline;
        }
    }
}

.single-question {
    margin-bottom: 12px;
    .question-name {
        color: #333333;
        font-weight: bold;
    }
    .options-list {
        display: flex;
        margin-top: 4px;
    }
    .single-option {
        padding: 8px 12px;
        margin-right: 8px;
        cursor: pointer;
    }
}

.badge {
    height: 200px;
    position: absolute;
    right: 40%;
    top: 5%;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
        right: 10px;
        top: 10px;
        height: 80px;
        left: auto;
    }
}

.header.singlepage img {
    width: 40rem;
    height: auto;
    margin: 2rem;
    max-width: 80%;
    max-height: 80%;
}

.header.singlepage {
    display: flex;
    align-items: center;
    justify-content: center;
}
