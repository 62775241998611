.accordion {
    max-width: 800px;
    margin: 12px auto;
    margin-top: 64px;
    .accordion-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    .accordion-box {
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0px 3px 6px #00000029;
    }
    .accordion-single {
        background-color: white;

        padding: 1rem;

        border-bottom: 1px solid #ccc;

        cursor: pointer;
        .accordion-question {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            font-size: 1.2rem;
            img {
                height: 28px;
            }
        }
        &.is-open {
            .accordion-question {
                img {
                    transition: all 0.2s ease;
                    transform: rotate(180deg);
                }
            }
        }
        .accordion-content {
            max-height: 0;
            overflow: hidden;
            transition: 0.3s all ease;

            &.open {
                max-height: 200px;
                transition: 0.3s all ease;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        margin: 12px;
    }
}

.footer {
    margin: 12px auto;
    padding-top: 64px;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    font-size: 12px;
    @media only screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.top-header {
    height: 60px;
    background-color: #125185;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-box {
    position: absolute;
    right: 8px;
    .gut {
        color: #fccd2c;
        margin-bottom: 32px;
        text-align: end;
        letter-spacing: 1.6px;
        font-weight: bold;
    }
    .user-Image {
        height: 48px;
        width: 48px;
        background-position: center;
        background-size: cover;
        margin-left: 12px;
        border-radius: 50%;
        position: relative;
        .status {
            height: 10px;
            width: 10px;
            position: absolute;
            bottom: 2px;
            right: 2px;
            border-radius: 50%;
            background-position: center;
            background-size: 90%;
        }
    }
    @media only screen and (max-width: 1024px) {
        display: none;
    }
}
.menu-list-out {
    height: 100%;
    .close {
        display: none;
    }
    @media only screen and (max-width: 1024px) {
        .close {
            display: block;
            position: absolute;
            top: 32px;
            right: 32px;
            img {
                height: 24px;
            }
        }
        font-size: 20px;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background-color: #125185;
        overflow: scroll;
        top: 0;
        left: 100vw;
        z-index: 99999;
        transition: 0.3s all ease-in-out;
        &.open {
            left: 0;
            transition: 0.3s all ease-in-out;
        }
    }
}
.menu-list {
    display: flex;
    position: relative;
    height: 100%;
    .menu-item {
        padding-left: 24px;
        padding-right: 24px;
        color: white;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        &.start {
            display: none;
            @media only screen and (max-width: 1024px) {
                display: flex;
            }
        }
        &:hover {
            background-color: rgb(74, 143, 222);
        }
    }

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        .menu-item {
            justify-content: center;
            flex-direction: column;
            padding-top: 32px;
            padding-bottom: 32px;
            height: fit-content;
            width: 100%;
        }
    }
}

.header-bar {
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    .header-bar-container {
        flex-grow: 1;
        max-width: 1100px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .logo {
            position: absolute;
            left: 8px;
        }
    }

    .menu-burger {
        display: none;
        @media only screen and (max-width: 1024px) {
            display: block;
        }
    }

    .submenu-list {
        color: black;
        position: absolute;
        background-color: white;
        top: calc(100%);
        white-space: nowrap;
        z-index: 999;
        left: 0;
        color: #125083;
        min-width: 160px;
        box-shadow: 0px 2px 2px #00000029;

        .singlesub {
            padding: 12px;
            &:hover {
                background: #ddd;
            }
        }
        @media only screen and (max-width: 1024px) {
            margin-top: 16px;
            background-color: transparent;
            color: white;
            position: relative;
            top: 0;
            box-shadow: none;
            text-align: center;
        }
    }

    @media only screen and (max-width: 1024px) {
        .user-Image {
            display: none;
        }
        .header-bar-container {
            padding: 0 12px;
        }
    }
}

.formular-field {
    margin: 0 auto;
    max-width: 1100px;
    z-index: 9999;
    margin-bottom: 32px;
    position: relative;
    grid-auto-flow: column;
    grid-auto-rows: max-content; /* OR min-content*/
    grid-template-rows: auto auto;
    &.newsletter {
        margin-top: 1rem;
        grid-template-columns: 1fr;
    }
    form {
        display: block;
    }
    label {
        display: block;
    }
    button {
        display: block;
        border: none;
    }
}

.formular-content {
    background-color: white;
    padding: 24px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    display: flex;
    position: relative;
    flex-direction: column;

    label {
        display: block;
    }
    .formular-sub-title {
        color: #fccd2c;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .formular-title {
        color: #125185;
        font-size: 36px;
        margin-bottom: 12px;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 24px;
        }
    }
    .label {
        margin-bottom: 16px;
        margin-top: 24px;
        color: #333333;
        font-weight: bold;
    }
    input {
        height: 42px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 3px;
        width: 100%;
        font-size: 16px;
        padding: 8px;
    }
    .formular-small-text {
        color: #333333;
        opacity: 0.5;
        font-size: 12px;
        margin: 16px 0;
    }
    .formular-button {
        width: 100%;
        background-color: #125185;
        color: white;
        text-align: center;
        padding: 16px;
        cursor: pointer;
        border-radius: 3px;
        letter-spacing: 1.6px;
    }
    @media only screen and (max-width: 1024px) {
        margin: 0 12px;
        display: flex;
    }
}

.formular-box-list {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .formular-box {
        background-color: #eee;
        padding: 16px;
        border-radius: 6px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: #ccc 1px solid;
        text-decoration: none;
        transition: background-color 0.3s ease;
        &:hover {
            background: #fff;
            cursor: pointer;
        }
        img {
            margin: 12px auto;
            margin-bottom: 32px;
            width: auto;
            max-height: 8rem;
        }
        .box-title {
            color: #125185;
            font-size: 20px;
            text-align: center;
            margin-bottom: 0.5rem;
            font-weight: bold;
        }
        .box-subline {
            color: #333333;
            opacity: 0.75;
            text-align: center;
        }
        .link {
            text-align: center;
            margin-top: 12px;
            color: #125083;
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
            &:hover {
                color: #333333;
            }
        }
    }
}
